import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Index from '../views/Index.vue'
import { Message } from 'element-ui'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // redirect: '/home',
    component: Index,
    meta: {
      isNav: false,
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component:  () => import(/* webpackChunkName: "Home" */ '../views/home/home.vue'),
        meta: {
          title: '首页',
          isNav: true,
          isLogin: true,
          icon: 'https://www.schen.cc/wall2.0/icons3/home_.svg',
          activeIcon: 'https://www.schen.cc/wall2.0/icons3/home.svg',
          specialPower: 1, // 用来控制 用户权限满足时 显示导航
        }
      },
      {
        path: '/wall-list',
        name: 'wall-list',
        component: () => import(/* webpackChunkName: "WallList" */ '../views/wallpaper/wallList.vue'),
        meta: {
          title: '壁纸列表',
          isNav: true,
          isLogin: true,
          icon: 'https://www.schen.cc/wall2.0/icons3/wall_.svg',
          activeIcon: 'https://www.schen.cc/wall2.0/icons3/wall.svg',
          specialPower: 1, // 用来控制 用户权限满足时 显示导航
        }
      },
      {
        path: '/tougao-list',
        name: 'tougao-list',
        component: () => import(/* webpackChunkName: "tougaoList" */ '../views/tougao/tougaoList.vue'),
        meta: {
          title: '投稿列表',
          isNav: true,
          isLogin: true,
          icon: 'https://www.schen.cc/wall2.0/icons3/tougao_.svg',
          activeIcon: 'https://www.schen.cc/wall2.0/icons3/tougao.svg',
          specialPower: 1, // 用来控制 用户权限满足时 显示导航
        }
      },
      {
        path: '/user-list',
        name: 'user-list',
        component: () => import(/* webpackChunkName: "userList" */ '../views/user/userList.vue'),
        meta: {
          title: '用户列表',
          isNav: true,
          isLogin: true,
          icon: 'https://www.schen.cc/wall2.0/icons3/user_.svg',
          activeIcon: 'https://www.schen.cc/wall2.0/icons3/user.svg',
          specialPower: 1, // 用来控制 用户权限满足时 显示导航
        }
      },
      {
        path: '/problem-list',
        name: 'problem-list',
        component: () => import(/* webpackChunkName: "problemList" */ '../views/problem/problemList.vue'),
        meta: {
          title: '意见反馈',
          isNav: true,
          isLogin: true,
          icon: 'https://www.schen.cc/wall2.0/icons3/problem_.svg',
          activeIcon: 'https://www.schen.cc/wall2.0/icons3/problem.svg',
          specialPower: 9, // 用来控制 用户权限满足时 显示导航
        }
      },
      {
        path: '/mj',
        name: 'mj',
        component: () => import(/* webpackChunkName: "mj" */ '../views/mj/mj.vue'),
        meta: {
          title: '魔法咒语',
          isNav: true,
          isLogin: true,
          icon: 'https://www.schen.cc/wall2.0/icons3/mj_.svg',
          activeIcon: 'https://www.schen.cc/wall2.0/icons3/mj.svg',
          specialPower: 9, // 用来控制 用户权限满足时 显示导航
        }
      },
      {
        path: '/bug',
        name: 'bug',
        component: () => import(/* webpackChunkName: "bug" */ '../views/bug/bug.vue'),
        meta: {
          title: 'BUG列表',
          isNav: true,
          isLogin: true,
          icon: 'https://www.schen.cc/wall2.0/icons3/bug_.svg',
          activeIcon: 'https://www.schen.cc/wall2.0/icons3/bug.svg',
          specialPower: 9, // 用来控制 用户权限满足时 显示导航
        }
      },
      {
        path: '/config',
        name: 'config',
        component: () => import(/* webpackChunkName: "config" */ '../views/config/config.vue'),
        redirect: '/config/swiper',
        meta: {
          title: '配置列表',
          isNav: true,
          isLogin: true,
          icon: 'https://www.schen.cc/wall2.0/icons3/config_.svg',
          activeIcon: 'https://www.schen.cc/wall2.0/icons3/config.svg',
          specialPower: 9, // 用来控制 用户权限满足时 显示导航
        },
        children: [
          {
            path: '/config/swiper',
            name: '轮播配置',
            component: () => import(/* webpackChunkName: "conSwiper" */ '../views/config/conSwiper/conSwiper.vue'),
          }, {
            path: '/config/class',
            name: '首页分类配置',
            component: () => import(/* webpackChunkName: "conClass" */ '../views/config/conClass/conClass.vue'),
          }, {
            path: '/config/tips',
            name: '标签配置',
            component: () => import(/* webpackChunkName: "conTips" */ '../views/config/conTips/conTips.vue'),
          }, {
            path: '/config/albums',
            name: '专辑配置',
            component: () => import(/* webpackChunkName: "conAlbums" */ '../views/config/conAlbums/conAlbums.vue'),
          }
        ]
      },
      {
        path: '/tool',
        name: 'tool',
        component: () => import(/* webpackChunkName: "tool" */ '../views/tool/tool.vue'),
        redirect: '/tool/channel',
        meta: {
          title: '专属工具',
          isNav: true,
          isLogin: true,
          icon: 'https://www.schen.cc/wall2.0/icons3/tool_.svg',
          activeIcon: 'https://www.schen.cc/wall2.0/icons3/tool.svg',
          specialPower: 9, // 用来控制 用户权限满足时 显示导航
        },
        children: [
          {
            path: '/tool/channel',
            name: '壁纸渠道',
            component: () => import(/* webpackChunkName: "toolChannel" */ '../views/tool/toolChannel/toolChannel.vue'),
          }, {
            path: '/tool/wall/download',
            name: '壁纸下载',
            component: () => import(/* webpackChunkName: "toolChannel" */ '../views/tool/toolWallDownload/toolWallDownload.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      isNav: false,
      isLogin: true,
      icon: 'https://www.schen.cc/wall2.0/icons3/home.svg',
      activeIcon: 'https://www.schen.cc/wall2.0/icons3/home_.svg'
    }
  },
  
]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('kehantoken');
  let userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {};
  if (to.name != 'login' && (!token || token < (new Date().getTime()-1000*60*60*24) || !userInfo || !userInfo.userName)) {
      Message({
          type: "error",
          message: '登录超时'
      })
      return next({ name: 'login' })
  } 
  else {
      console.log('没有超时')
      store.commit('uploadUserInfo', userInfo)
      return next()
  }
})
export default router
