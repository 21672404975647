import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import axios from 'axios'

import xbLoading from '@/components/comLoading.vue';

Vue.config.productionTip = false;

Vue.component('xbLoading', xbLoading);
Vue.use(ElementUI);

axios.defaults.baseURL = "https://fc-mp-194ee662-70f4-4c3f-8bae-0ff586fb7994.next.bspapp.com";
// axios.defaults.baseURL = "https://kehan.schen.cc";
axios.defaults.timeout = 30000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

Vue.prototype.$post = (url, params = {}) => {
    //   if(url != '/admin/login'){
    //     params.token = store.state.token ? store.state.token : '';
    //     params.userId = store.state.userInfo && store.state.userInfo.userId ? store.state.userInfo.userId : '';
    //   }
    params.token = store.state.token ? store.state.token : '';
    params.userId = store.state.userInfo && store.state.userInfo.userId ? store.state.userInfo.userId : '';
    return new Promise((resolve, reject) => {
        axios.post(url, JSON.stringify(params)).then(res => {
            if (res.data.code == 210) {
                ElementUI.Message({
                    type: 'error',
                    message: '登录超时'
                })
                sessionStorage.removeItem('kehantoken');
                sessionStorage.removeItem('userInfo')
                store.commit('uploadToken', '');
                store.commit('uploadUserInfo', {});
                if (window.location.pathname != '/login') {
                    router.push('/login')
                }
                resolve(res.data);
            } else if (res.data.code == 211) {
                ElementUI.Message({
                    type: 'error',
                    message: '你没有操作权限'
                })
            } else if (res.data.code == 222) {
                ElementUI.Message({
                    type: 'error',
                    message: '您不是管理员'
                })
                sessionStorage.removeItem('kehantoken');
                sessionStorage.removeItem('userInfo')
                store.commit('uploadToken', '');
                store.commit('uploadUserInfo', {});
                if (window.location.pathname != '/login') {
                    router.push('/login')
                }
            } else {
                resolve(res.data);
            }
            //   resolve(res);
        }).catch(err => {
            reject(err);
        })
    });
};

Vue.prototype.$fGetNowTime = (type) => {
    let date = new Date();
    let time = date.getTime();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let week = date.getDay();
    if (type == 'time') {
        return time + ''
    } else if (type == 'array') {
        return [year, month, day, hour, minute, second]
    } else if (type == 'format') {
        return year + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day) + ' ' + (hour > 9 ? hour : '0' + hour) + ':' + (minute > 9 ? minute : '0' + minute) + ':' + (second > 9 ? second : '0' + second)
    } else if (type == 'path') {
        return year + '' + (month > 9 ? month : '0' + month) + '' + (day > 9 ? day : '0' + day) + ''
    } else if (type == 'week') {
        switch (week) {
            case 0:
                return '星期日';
            case 1:
                return '星期一';
            case 2:
                return '星期二';
            case 3:
                return '星期三';
            case 4:
                return '星期四';
            case 5:
                return '星期五';
            case 6:
                return '星期六';
        }
    }
}

Vue.prototype.$qiniuUrl = "https://www.schen.cc/";

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
