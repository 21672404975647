import { render, staticRenderFns } from "./comLoading.vue?vue&type=template&id=58f7e17f&scoped=true"
import script from "./comLoading.vue?vue&type=script&lang=js"
export * from "./comLoading.vue?vue&type=script&lang=js"
import style0 from "./comLoading.vue?vue&type=style&index=0&id=58f7e17f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f7e17f",
  null
  
)

export default component.exports