<template>
    <div class='page_login'>
        <div class="login_wrap">
            <div class="summary">
                <div class="sum_logo">
                    <div class="logo_box"><img src="../assets/icon/logo.svg" /></div>
                    <div class="logo_title">Schen.cc</div>
                </div>
                <div class="sum_title">珂涵小程序后天管理工具</div>
                <div class="sum_desc">珂涵，用来管理小程序上的壁纸内容、用户投稿、反馈和配置内容，辅助小程序进行完善的工作</div>
            </div>
            <div class="cont">
                <div class="cont_thumb"><img src="../assets/imgs/login-img.jpg" /></div>
                <el-form :model="userInfo" :rules="rules" ref="ruleForm" label-width="0">
                    <el-form-item prop="username">
                        <div class="form_item_box">
                            <!-- <div class="item_icon"><img src="../assets/logo.png" /></div> -->
                            <el-input v-model="userInfo.username" placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop="userpwd">
                        <div class="form_item_box">
                            <!-- <div class="item_icon"><img src="../assets/logo.png" /></div> -->
                            <el-input v-model="userInfo.userpwd" placeholder="请输入密码" show-password prefix-icon="el-icon-unlock" @keyup.enter.native="submitForm('ruleForm')"></el-input>
                        </div>
                    </el-form-item>
                    <div class="forget_pwd">忘记密码？</div>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLogining" :aria-disabled="isLogining">立即登录</el-button>
                    </el-form-item>
                    <div class="not_code">没有账号？点击联系管理员</div>
                </el-form>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data(){
            return {
                userInfo: {
                    username: '',
                    userpwd: ''
                },
                rules: {
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' }
                    ],
                    userpwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ]
                },
                isLogining: false
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.fLogin();
                    } else {
                        return false;
                    }
                });
            },
            async fLogin(){
                console.log('89sa6gyiasugiuas6f807sagholas')
                if(!this.userInfo.username){
                    return this.$message({
                        type: 'warning',
                        message : '请输入账号',
                    })
                }
                if(!this.userInfo.userpwd){
                    return this.$message({
                        type: 'warning',
                        message: '请输入密码'
                    })
                }
                this.isLogining = true;
                let res = await this.$post('/admin/login', {
                    adminCode: this.userInfo.username,
                    adminPwd: this.userInfo.userpwd,
                });
                console.log(res)
                if(res.code == 200){
                    // 登录成功
                    sessionStorage.setItem('kehantoken', res.data.token);
                    sessionStorage.setItem('userInfo', JSON.stringify(res.data));
                    this.$store.commit('uploadToken', res.data.token);
                    this.$store.commit('uploadUserInfo', res.data);
                    this.$message({
                        type: 'success',
                        message: `登录成功, 欢迎你，${res.data.userName}`
                    })
                    setTimeout(() => {
                        this.$router.replace('/home')
                    }, 1000)
                } else if(res.code == 202) {
                    this.$message({
                        type: 'error',
                        message: '密码错误'
                    })
                    this.isLogining = false;
                } else {
                    this.$message({
                        type: 'error',
                        message: '登录失败，请联系管理员'
                    })
                    this.isLogining = false;
                }
            }
        }
    }
</script>
<style lang='scss'>
    .page_login {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        .login_wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 100px;
            .summary {
                max-width: 400px;
                .sum_logo {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .logo_box {
                        width: 48px;
                        height: 48px;
                        background-color: #2A7FFF;
                        border-radius: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 24px;
                            height: 24px;
                            object-fit: contain;
                        }
                    }
                    .logo_title {
                        font-size: 20px;
                        font-weight: bold;
                        color: #141414;
                    }
                }
                .sum_title {
                    font-size: 28px;
                    font-weight: 600;
                    margin-top: 30px;
                    color: #333;
                }
                .sum_desc {
                    font-size: 16px;
                    color: #333;
                    opacity: 0.6;
                    line-height: 1.5em;
                    margin-top: 20px;

                }
            }
            .cont {
                padding: 40px 30px 30px;
                border-radius: 16px;
                box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.03);
                background-color: #fff;
                .cont_thumb {
                    width: 360px;
                    height: 230px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .el-form {
                    margin-top: 30px;
                    .form_item_box {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        border: 1px solid #E0DEED;
                        border-radius: 12px;
                        height: 48px;
                        .item_icon {
                            img {
                                width: 24px;
                                height: 24px;
                                object-fit: contain;
                            }
                        }
                    }
                    .el-button {
                        width: 100%;
                        height: 48px;
                        border-radius: 8px;
                        margin-top: 30px;
                        font-size: 16px;
                    }
                    .forget_pwd {
                        font-size: 12px;
                        text-align: right;
                        margin-top: -4px;
                    }
                    .not_code {
                        font-size: 14px;
                        text-align: center;
                        opacity: 0.6;
                        margin-top: -4px;
                    }
                }
            }
        }
        .el-input__inner {
            border: none;
            border-radius: 16px;
            padding-left: 44px;
            font-size: 15px;
        }
        .el-input__icon {
            width: 40px;
        }
        .el-input__prefix {
            font-size: 20px;
        }
    }
</style>