import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      token: 78560129346128734,
      userInfo: {},
      albumData: {},
      tipsData: {},
  },
  getters: {},
  mutations: {
      uploadToken(state, token){
          state.token = token
      },
      uploadUserInfo(state, obj){
          state.userInfo = JSON.parse(JSON.stringify(obj))
      },
      uploadTipsAndAlbum(state, obj){
          state.albumData = JSON.parse(JSON.stringify(obj.albumData.oAlbumArr))
          state.tipsData = JSON.parse(JSON.stringify(obj.tipsData.oTipsArr))
      },
  },
  actions: {},
  modules: {}
})
