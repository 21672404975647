<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuState: false,
    }
  },
  created(){
    console.log('刷新是否执行')
    // this.fGetHomeData()
  },
  methods: {
    fGlobal(type, val = "") {
      switch (type) {
        case 'toggle-menu':
          this.menuState = !this.menuState;
          break;
        default:
          this.$message({
            type: 'warning',
            message: '请传入type值'
          })
      }
    },
    
    async fGetHomeData(){
        let res = await this.$post('/admin/wall-other');
        console.log(res)
        if(res.code == 200){
            this.$store.commit('uploadTipsAndAlbum', res.data)
        }
        console.log(this.$store.state.albumData)
    }
  }
}
</script>

<style lang="scss">
#app {
  background-color: #F4F7FD;

  .el-button {
    border-radius: 8px;
  }

  .el-input__inner,.el-textarea__inner {
    border-radius: 8px;
  }

  .el-upload {
    width: 100%;
    height: 100%;
  }
  // 滚动条设置
  ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
  }
  ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #ccc;
      filter: opacity(0.5);
  }
}
</style>
