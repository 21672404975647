<template>
    <div class='page-index'>
        <el-container>
            <el-aside :width="menuState ? '104px' : '240px'" :class="[menuState ? 'meun_state' : '']">
                <div class="nav_header">
                    <div class="h_avatar">
                        <div class="img_box"><img :src="userInfo.userAvatar" /></div>
                    </div>
                    <div class="h_cont">
                        <div class="cont_weather">晴</div>
                        <div class="cont_username">{{ userInfo.userName }}</div>
                    </div>
                    <div class="h_fold" @click="fGlobal('toggle-menu')"><i class="el-icon-arrow-left"></i></div>
                </div>
                <div class="nav-search">
                    <i class="el-icon-search"></i>
                    <input v-model="searchVal" placeholder="输入壁纸ID" />
                </div>
                <div class="nav_body">
                    <div class="nav_item" v-for="(nav, idx) in routeList" :key="idx" v-if="nav.meta.isNav && userInfo.power && userInfo.power > nav.meta.specialPower">
                        <router-link :to="nav.path">
                            <div class="nav_icon">
                                <img class="icon_default" :src="nav.meta.icon" />
                                <img class="icon_active" :src="nav.meta.activeIcon" />
                            </div>
                            <div class="nav_title">{{ nav.meta.title }}</div>
                            <div class="nav_line"></div>
                        </router-link>
                    </div>

                </div>
                <div class="nav_footer">
                    <div class="add_wall_btn" @click="fGlobal('navigate')"><i class="el-icon-plus"></i><span>新增壁纸</span></div>
                </div>
            </el-aside>
            <el-main>
                <router-view ref="comRef" />
            </el-main>
        </el-container>
        <xbLoading ref="idxLoading"></xbLoading>
    </div>
</template>


<script>
// import comLoading from '@/components/comLoading.vue';
export default {
    // components: {
    //     comLoading
    // },
    data() {
        return {
            menuState: false,
            searchVal: '',
            userInfo: {},
            routeList: []
        }
    },
    created() {
        this.routeList = this.$router.options.routes[0].children;
        this.userInfo = this.$store.state.userInfo;
        console.log(this.userInfo);
    },
    methods: {
        fGlobal(type, val = "") {
            switch (type) {
                case 'toggle-menu':
                    this.menuState = !this.menuState;
                    break;
                case 'navigate':
                    if(this.$route.name == "wall-list"){
                        this.$refs.comRef.fGlobal('add-upload');
                    } else {
                        this.$router.push('/wall-list?add=true');
                    }
                    break;
                default:
                    this.$message({
                        type: 'warning',
                        message: '请传入type值'
                    })
            }
        }
    }
}
</script>
<style lang='scss'>
.page-index {
    .el-container {
        height: 100vh;
        padding: 10px;

        .el-aside {
            padding: 32px 20px 20px;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 0 50px rgba(0, 0, 0, 0.03);
            position: relative;
            transition: 0.2s;
            overflow: unset;

            .nav_header {
                display: flex;
                gap: 16px;
                align-items: center;
                position: relative;

                .h_avatar {
                    flex: 0 0 64px;
                    width: 64px;
                    height: 64px;

                    .img_box {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgba(0, 0, 0, 0.06);
                        border-radius: 24px;
                        position: relative;

                        img {
                            width: 48px;
                            height: 48px;
                            object-fit: cover;
                            border-radius: 16px;
                        }

                        &::after {
                            content: "";
                            width: 3px;
                            height: 16px;
                            background-color: #2A7FFF;
                            border-radius: 1px 2px 2px 1px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &::before {
                            content: "";
                            width: 7px;
                            height: 7px;
                            background-color: #16E298;
                            border-radius: 50%;
                            position: absolute;
                            top: 0;
                            right: 0;
                            transform: translate(-25%, 25%);
                        }
                    }
                }

                .h_cont {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    transition: 0.2s;

                    .cont_weather {
                        font-size: 12px;
                        font-weight: bold;
                        color: #999;
                    }

                    .cont_username {
                        font-size: 16px;
                        font-weight: bold;
                        color: #141414;
                        white-space: nowrap;
                    }
                }

                .h_fold {
                    position: absolute;
                    top: 50%;
                    right: -20px;
                    padding: 8px 0px 8px 2px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #2A7FFF;
                    transform: translateY(-50%);
                    background-color: rgba(41, 126, 255, 0.08);
                    border-radius: 10px 1px 1px 10px;
                    cursor: pointer;

                    i {
                        font-weight: bold;
                    }

                    &::after {
                        content: "";
                        width: 300%;
                        height: 200%;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                }
            }

            .nav-search {
                margin-top: 20px;
                background-color: rgba(41, 126, 255, 0.08);
                border-radius: 12px;
                padding: 14px 16px;
                display: flex;
                align-items: center;
                gap: 8px;
                transition: 0.2s;

                i {
                    color: #8CA3C4;
                }

                input {
                    border: none;
                    background: transparent;
                    font-size: 14px;
                    outline: none;
                    // width: 100px;
                    transition: width 0.4s;
                    opacity: 1;
                    pointer-events: all;

                    &::placeholder {
                        color: #8CA3C4;
                    }
                }
            }

            .nav_body {
                height: calc(100vh - 280px);
                overflow-x: hidden;
                overflow-y: auto;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .nav_item {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    >a {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        text-decoration: unset;
                        width: 100%;
                        padding: 12px 16px;
                        border-radius: 12px;
                        position: relative;
                        transition: 0.2s;

                        .nav_icon {
                            flex: 0 0 24px;
                            width: 24px;
                            height: 24px;
                            position: relative;

                            .icon_default {
                                position: absolute;
                                top: 0;
                                right: 0;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                opacity: 1;
                                transition: 0.1s;
                            }

                            .icon_active {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                position: absolute;
                                top: 0;
                                right: 0;
                                left: 0;
                                bottom: 0;
                                opacity: 0;
                                transition: 0.1s;
                            }
                        }

                        .nav_title {
                            font-size: 15px;
                            color: #666666;
                            white-space: nowrap;
                            pointer-events: all;
                            opacity: 1;
                            transition: 0.2s;
                        }

                        .nav_line {
                            width: 3px;
                            height: 32px;
                            border-radius: 4px;
                            position: absolute;
                            top: 50%;
                            right: -20px;
                            transform: translateY(-50%);
                            background-color: #2A7FFF;
                            opacity: 0;
                        }

                        &.router-link-active {
                            background-color: rgba(41, 126, 255, 0.08);

                            .nav_icon {
                                .icon_default {
                                    opacity: 0;
                                }

                                .icon_active {
                                    opacity: 1;
                                }
                            }

                            .nav_title {
                                color: #2A7FFF;
                            }

                            .nav_line {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .nav_footer {
                margin-top: auto;
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
                cursor: pointer;

                .add_wall_btn {
                    padding: 14px 16px;
                    border-radius: 8px;
                    background-color: #2A7FFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 14px;
                    gap: 4px;

                    i {
                        font-weight: bold;
                    }

                    span {
                        white-space: nowrap;
                        transition: 0.2s;
                    }
                }
            }

            // 折叠状态下的央视
            &.meun_state {
                .nav_header {
                    .h_cont {
                        flex: 0 0 0;
                        overflow: hidden;
                        opacity: 0;
                    }

                    .h_fold {
                        transform: translateY(-50%) rotateZ(180deg);
                        transform-origin: right;
                    }
                }

                .nav-search {
                    gap: 0;
                    justify-content: center;

                    input {
                        width: 0;
                        opacity: 0;
                        pointer-events: none;
                        padding: 0;
                    }
                }

                .nav_body {
                    .nav_item {
                        >a {
                            gap: 0;
                            justify-content: center;

                            .nav_title {
                                width: 0;
                                overflow: hidden;
                                opacity: 0;
                            }
                        }
                    }
                }

                .nav_footer {
                    .add_wall_btn {
                        gap: 0;

                        span {
                            width: 0;
                            opacity: 0;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .el-main {
            padding: 0;
            padding-left: 20px;
        }
    }
}
</style>